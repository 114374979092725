import { Speaker } from "../../app/SpeakerStore"

export const menuItems = [
  { href: "main", label: "Главная" },
  { href: "schedule", label: "Расписание" },
  { href: "speakers", label: "Спикеры" },
  { href: "askQuestion", label: "Задать вопрос" },
]

export type RoomSingleElement = {
  variant: "single",
} & Speaker

export type RoomMultiElement = {
  time: string,
  variant: "multi",
  title: string,
  tellsUs: string,
  team: Array<Speaker>
}

export type RoomElement = RoomSingleElement | RoomMultiElement

export const room1: Array<RoomElement> = [
  {
    variant: "single",
    time: "12:00 - 13:00",
    speakerName: "Вероника Филонская",
    description: "Врач-невролог детский, взрослый. Врач-психиатр",
    value: "filonskaya",
    title: "Неврологические ограничения и фитнес. Типичные ошибки врачей и тренеров",
    small: "assets/speakers/filonskaya/filonskaya-small.jpg",
    cover: "assets/speakers/filonskaya/filonskaya.jpg",
    texts: [
      "2016 г. - Волгоградский государственный медицинский университет. Лечебное дело",
      "2018 г. - Неврология, ординатура. Профессиональная переподготовка и повышение квалификации",
      "2016 г. - Боль - болезнь. От теории к практике",
      "2016 г. - Актуальные вопросы неврологии",
      "2018 г. - Клиническая неврология с основами нейрореабилитации",
      "2019 г. - \"Новые возможности диагностики и лечения головной боли\"",
      "2021 г. - Диагностика и лечение орофациальной боли, Москва",
      "2023 г. - Docmed Academy \"Расстройство развития и расстройство поведения\"",
      "2023 г. - Применение препарата Релатокс в неврологии",
      "2023 г. - Применение ботулинотерапии при ДЦП",
      "2023 г. - Профессиональная переподготовка по специальности \"Психиатрия\"",
      "2024 г. - Docmed Academy Курс Елисея Осина по детской и подростковой психиатрии",
      "2024 г. - Семинар \"СДВГ у детей и взрослых\"",
      "2024 г. - Основы медицинской генетики, Геномед",
      "2024 г. - Повышение квалификации \"Детская неврология в педиатрии\"",
      "2024 г. - Клиническая эпилептология с основами видео — ЭЭГ- мониторинга",
    ],
    social: {
      instagram: "https://www.instagram.com/dr_filonskaya",
    },
  },
  {
    variant: "single",
    time: "13:00 - 14:00",
    speakerName: "Никита Ющенко",
    description: "Персональный фитнес-тренер",
    value: "uschenko",
    title: "Оценка эффективности силовых упражнений или гадание на скелетной гуще? От ЭМГ до РКИ",
    small: "assets/speakers/uschenko/uschenko-small.jpg",
    cover: "assets/speakers/uschenko/uschenko.jpg",
    texts: [
      "ДВФУ (бакалавриат) \"Физическая культура\"",
      "FPA \"Персональный фитнес-тренер\"",
    ],
    social: {
      telegram: "https://t.me/tochno_trener",
      instagram: "https://www.instagram.com/tochno_trener",
      youtube: "https://www.youtube.com/c/ТочноТренер",
      custom: "https://boosty.to/tochno.trener",
    },
  },
  {
    variant: "single",
    time: "14:00 - 15:00",
    speakerName: "Виктор Козлов",
    description: "Эксперт по тренировочным вопросам, фитнес-тренер",
    value: "kozlov",
    title: "Ягодичный бум. Научный подход к тренировкам",
    small: "assets/speakers/kozlov/kozlov-small.jpg",
    cover: "assets/speakers/kozlov/kozlov.jpg",
    texts: [
      "Руководитель и основатель обучающего проекта по гипертрофийному тренингу Physical Education",
      "Главный эксперт по тренировочным вопросам в лучшем проекте по физической трансформации Physical Transformation",
      "15+ лет стажа изучения вопросов эффективного силового тренинга",
      "Сертифицированный тренер от Menno Henselmans 2019",
      "Сертифицированный тренер J3 University",
      "Сертифицированный тренер Hybrid training program design course от Алекса Виады",
    ],
  },
  {
    variant: "single",
    time: "15:00 - 16:00",
    speakerName: "Иван Кузнецов",
    description: "Тренер-реабилитолог",
    value: "ivan-kuznetsov",
    title: "Боль в спине, причины и решения",
    small: "assets/speakers/ivan-kuznetsov/ivan-kuznetsov-small.jpg",
    cover: "assets/speakers/ivan-kuznetsov/ivan-kuznetsov.jpg",
    texts: [
      "Специалист по восстановлению после травм для акробатов, капоэристов, бодибилдеров и т.п.",
      "Автор, ведущий проекта по здоровью ОДА с 2012 года",
    ],
  },
  {
    variant: "single",
    time: "16:00 - 17:00",
    speakerName: "Артём Спиридонов",
    description: "Тренер по фитнесу и бодибилдингу",
    value: "spiridonov",
    title: "Мифы и заблуждения о питании",
    small: "assets/speakers/spiridonov/spiridonov-small.jpg",
    cover: "assets/speakers/spiridonov/spiridonov.jpg",
    texts: [
      "Тренер по фитнесу и бодибилдингу - Колледж им.Вейдера",
      "Курсы онлайн в \"Академии фитнеса и бодибилдинга\"",
    ],
    social: {
      telegram: "https://t.me/Artem_trener",
      instagram: "https://www.instagram.com/artemtreno",
      vk: "https://www.www.vk.com/art_spiridon",
      tiktok: "https://www.tiktok.com/@art_trener",
      youtube: "https://youtube.com/@Trener_Artem",
      custom: "https://boosty.to/trenerartem",
    },
  },
]

export const room2: Array<RoomElement> = [
  {
    variant: "single",
    time: "12:00 - 13:00",
    speakerName: "Максим Афанасьев",
    description: "Магистр биохимии, персональный тренер, доказательный нутрициолог",
    value: "afanasiev",
    title: "Движение - жизнь. А сколько в метрах? Практические рекомендации",
    small: "assets/speakers/afanasiev/afanasiev-small.jpg",
    cover: "assets/speakers/afanasiev/afanasiev.jpg",
    texts: [
      "Магистратура Лесгафта по специальности Медико-биологическое сопровождение спортивной подготовки, кафедра биохимии, диплом с отличием",
      "Персональный тренер",
      "доказательный нутрициолог",
    ],
    social: {
      telegram: "https://t.me/maxnutrition",
      instagram: "https://www.instagram.com/maxnutritionkz",
      vk: "https://vk.com/marakaya",
      tiktok: "https://www.tiktok.com/@maxnutritionkz",
      youtube: "https://youtube.com/@MaximAfanasyevKZ",
    },
  },
  {
    variant: "single",
    time: "13:00 - 14:00",
    speakerName: "Даниил Дьяченко",
    description: "Персональный тренер",
    value: "diyachenko",
    title: "Тренер здорового человека и тренер курильщика. Построение личного бренда",
    small: "assets/speakers/diyachenko/diyachenko-small.jpg",
    cover: "assets/speakers/diyachenko/diyachenko.jpg",
    texts: [
      "Выпускник КГУФКСТ",
      "Mac-Nutrition University (Martin McDonald)",
      "Exercise professional Tom Purvis PT",
      "Мастер спорта по становой тяге AWPC",
      "Создатель обучения \"My Revolution University\"",
    ],
    social: {
      telegram: "https://t.me/dyachenkodanil",
      instagram: "https://www.instagram.com/danildyachenko",
    },
  },
  {
    variant: "single",
    time: "14:00 - 15:00",
    speakerName: "Максим Кузнецов",
    description: "Врач-эндокринолог",
    value: "maxim-kuznetsov",
    title: "Влияние гормонов на массу тела",
    small: "assets/speakers/maxim-kuznetsov/maxim-kuznetsov-small.jpg",
    cover: "assets/speakers/maxim-kuznetsov/maxim-kuznetsov.jpg",
    texts: [
      "Врач - эндокринолог",
      "Окончил ОрГМУ и СПБГПМУ",
      "Автор соц.сетей и курсов про здоровье, гормоны и питание",
    ],
    social: {
      telegram: "https://t.me/medic_smith",
      instagram: "https://www.instagram.com/medic_smith?igshid=YmMyMTA2M2Y=",
      tiktok: "https://www.tiktok.com/@medic_smith",
      youtube: "https://youtube.com/@medic_smith",
      custom: "https://medicsmith.ru/obezjirivatel",
    },
  },
  {
    variant: "single",
    time: "15:00 - 16:00",
    speakerName: "Марк Озолинь",
    description: "Врач по спортивной медицине и ЛФК, реабилитолог",
    value: "ozolin",
    title: "Ложь и правда про триггерные точки. Критический взгляд",
    small: "assets/speakers/ozolin/ozolin-small.jpg",
    cover: "assets/speakers/ozolin/ozolin.jpg",
    texts: [
      "Окончил Тверской ГМУ, педиатрический факультет",
      "Ординатура: ЛФК и спортивная медицина",
      "Работает в сфере реабилитации с 2010 года",
      "Преподавательская деятельность с 2017 года",
      "Экс-врач мужской сборной России по кёрлингу",
      "Член Европейской федерации боли EFIC",
    ],
    social: {
      telegram: "https://t.me/dr_ozolin",
      instagram: "https://www.instagram.com/dr_ozolin",
      youtube: "https://youtube.com/@dr_ozolin",
    },
  },
  {
    variant: "multi",
    time: "16:00 - 17:00",
    title: "Ответы на вопросы слушателей",
    tellsUs: "Рыцари круглого стола",
    team: [
      {
        time: "",
        title: "",
        speakerName: "Александр Бурлаков",
        description: "Врач-диетолог доказательной медицины",
        value: "burlakov",
        small: "assets/speakers/burlakov/burlakov-small.jpg",
        cover: "assets/speakers/burlakov/burlakov.jpg",
        texts: [
          "Врач-диетолог доказательной медицины. Тренер - преподаватель. Научный журналист",
          "Специалист по диетологическому сопровождению спортсменов UFC, ACA, RCC",
          "Автор книги: \"В гармонии с едой\"",
        ],
      },
      {
        time: "",
        title: "",
        speakerName: "Алексей Варфоломеев",
        description: "Специалист по модификации образа жизни",
        value: "varfolomeev",
        small: "assets/speakers/varfolomeev/varfolomeev-small.jpg",
        cover: "assets/speakers/varfolomeev/varfolomeev.jpg",
        texts: [
          "Специалист по модификации образа жизни",
          "Нутрициолог",
          "Судья международного класса по вейкборду IWWF/CWWC",
        ],
      },
      {
        time: "",
        title: "",
        speakerName: "Валерия Федоровских",
        description: "Врач-педиатр",
        value: "fedorovskih",
        small: "assets/speakers/fedorovskih/fedorovskih-small.jpg",
        cover: "assets/speakers/fedorovskih/fedorovskih.jpg",
        texts: [
          "Закончила педиатрический факультет ТюмГМУ с отличием",
          "Закончила ординатуру по специальности \"Педиатрия\"",
          "Преподаватель биологической химии студентам медицинских ВУЗов",
        ],
        social: {
          telegram: "https://t.me/valerunaa",
          instagram: "https://www.instagram.com/valeruna_?igsh=aHFwcmZnbDExODhs",
        },
      },
      {
        time: "",
        title: "",
        speakerName: "Анна Берекетова",
        description: "Нутрициолог",
        value: "bereketova",
        small: "assets/speakers/bereketova/bereketova-small.jpg",
        cover: "assets/speakers/bereketova/bereketova.jpg",
        texts: [
          "Специалист по питанию и пищевому поведению",
          "Преподаватель и методист учебного центра Body Coach",
          "Ассистент врача-диетолога Александра Бурлакова",
        ],
      },
    ],
  },
]
