import React from "react"
import { Center, Container, createStyles, Text, Title, Group, Badge, Button } from "@mantine/core"
import { useMediaQuery } from "@mantine/hooks"
import { primaryColor } from "../../constants"

const useStyles = createStyles((theme) => ({
  wrapper: {
    paddingTop: 60,
    paddingBottom: 60,
  },

  title: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    marginBottom: 48,
    position: "relative",
    paddingBottom: 20,

    "&::before": {
      content: "\"\"",
      position: "absolute",
      display: "block",
      width: 90,
      height: 5,
      background: theme.colors[primaryColor][9],
      bottom: 0,
      left: "calc(50% - 45px)",
    },
  },
}))

const data = [
  {
    text: "Нутрициологам",
    from: "dark-green",
    to: "teal",
  },
  {
    text: "Медицинским специалистам",
    from: "#667db6",
    to: "#0082c8",
  },
  {
    text: "Работникам сферы красоты и здоровья",
    from: "red",
    to: "pink",
  },
  {
    text: "Фитнес-экспертам",
    from: "#CC95C0",
    to: "#DBD4B4",
  },
  {
    text: "Тем, кто интересуется научным подходом в фитнесе",
    from: "yellow",
    to: "orange",
  },
]

export function BeInterested(): React.JSX.Element {
  const { classes } = useStyles()
  const isMobile = useMediaQuery(`(max-width: 480px)`)

  return (
    <section className={classes.wrapper}>
      <Container size={1024}>
        <Center className={classes.title}>
          <Title color="#122c0f" order={2} align="center" size={32}>Кому будет точно интересно?</Title>
        </Center>

        <Group position="center">
          {data.map(({ text, from, to }, index) => (
            <Badge
              miw={288}
              size={isMobile ? "md" : "xl"}
              key={index}
              variant="gradient"
              gradient={{ from, to, deg: 35 }}
              py="md"
            >
              {text}
            </Badge>
          ))}
        </Group>

      </Container>
    </section>
  )
}
