import React from "react"
import { Center, Container, createStyles, Title, Group } from "@mantine/core"
import { PricingPlanSection } from "./PricingPlanSection"
import { PricingFreeSection } from "./PricingFreeSection"
import { Timer } from "../timer"
import { primaryColor } from "../../constants"
import { PricingMaxSection } from "./PricingMaxSection"

const useStyles = createStyles((theme) => ({
  wrapper: {
    marginTop: 20,
    paddingTop: 40,
    marginBottom: 20,
    paddingBottom: 40,
    backgroundColor: theme.colors[primaryColor][0],
    position: "relative",
    overflowX: "clip",
  },

  wave1: {
    top: 0,
    left: 0,
    display: "block",
    position: "absolute",
    height: 40,
    width: "100%",
    background: theme.colors[primaryColor][0],
    transform: "scale(1, 1)",

    "&::before": {
      content: "''",
      display: "block",
      position: "absolute",
      borderRadius: "100%",
      width: "100%",
      height: 300,
      backgroundColor: theme.colors[primaryColor][0],
      right: "-25%",
      top: -20,
    },

    "&::after": {
      content: "''",
      display: "block",
      position: "absolute",
      borderRadius: "100%",
      width: "100%",
      height: 300,
      backgroundColor: theme.white,
      left: "-25%",
      top: -280,
      clipPath: "ellipse(100% -15% at 15% 100%)",
    },
  },

  wave2: {
    bottom: 0,
    left: 0,
    display: "block",
    position: "absolute",
    height: 40,
    width: "100%",
    background: theme.colors[primaryColor][0],
    transform: "scale(1, 1)",

    "&::before": {
      content: "''",
      display: "block",
      position: "absolute",
      borderRadius: "100%",
      width: "100%",
      height: 300,
      backgroundColor: "white",
      right: "-25%",
      top: 20,
    },

    "&::after": {
      content: "''",
      display: "block",
      position: "absolute",
      borderRadius: "100%",
      width: "100%",
      height: 300,
      backgroundColor: theme.colors[primaryColor][0],
      left: "-25%",
      top: -240,
      clipPath: "ellipse(100% 15% at -15% 100%)",
    },
  },

  title: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    marginBottom: 48,
    position: "relative",
    paddingBottom: 20,

    "&::before": {
      content: "\"\"",
      position: "absolute",
      display: "block",
      width: 90,
      height: 5,
      background: theme.colors[primaryColor][8],
      bottom: 0,
      left: "calc(50% - 45px)",
    },
  },
}))

export function Prices(): React.JSX.Element {
  const { classes } = useStyles()

  const currentDate = new Date()
  const targetFirstDate = new Date("2024-11-11")
  const targetSecondDate = new Date("2024-11-18")
  const targetThirdDate = new Date("2024-11-25")

  const showFirst = currentDate < targetFirstDate
  const showSecond = !showFirst && currentDate < targetSecondDate
  const showThird = !showFirst && !showSecond && currentDate < targetThirdDate

  return (
    <section id="prices" className={classes.wrapper}>
      <div className={classes.wave1}/>
      <div className={classes.wave2}/>

      <Container size={1320}>
        <Center className={classes.title}>
          <Title color="#122c0f" order={2} align="center" size={32}>Стоимость участия</Title>
        </Center>

        <Group spacing={32} align="stretch" position="center">
          <PricingFreeSection/>
          <PricingPlanSection/>
          <PricingMaxSection/>
        </Group>

        {showFirst && <Timer date={"2024-11-11T00:00:00.000+03:00"}/>}
        {showSecond && <Timer date={"2024-11-18T00:00:00.000+03:00"}/>}
        {showThird && <Timer date={"2024-11-25T00:00:00.000+03:00"}/>}

      </Container>
    </section>
  )
}
