import React from "react"
import { Center, Container, createStyles, Title, Card, SimpleGrid, ThemeIcon, List } from "@mantine/core"
import {
  IconHash,
  IconHeartbeat,
  IconCoins,
  IconBrain,
  IconCarrot,
  IconYoga,
} from "@tabler/icons-react"
import { primaryColor } from "../../constants"

const useStyles = createStyles((theme) => ({
  wrapper: {
    paddingTop: 60,
    paddingBottom: 60,
    zIndex: 1,
    position: "relative",
  },

  title: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    marginBottom: 48,
    position: "relative",
    paddingBottom: 20,

    "&::before": {
      content: "\"\"",
      position: "absolute",
      display: "block",
      width: 90,
      height: 5,
      background: theme.colors[primaryColor][9],
      bottom: 0,
      left: "calc(50% - 45px)",
    },
  },

  cardTitle: {
    position: "relative",
    paddingBottom: 32,

    "&::before": {
      content: "\"\"",
      position: "absolute",
      display: "block",
      width: 120,
      height: 3,
      background: theme.colors[primaryColor][9],
      boxShadow: `${theme.colors[primaryColor][9]} 0px 0px 50px`,
      bottom: 0,
      left: 0,
    },
  },

  card: {
    width: "100%",
    margin: "0 auto",
  },
}))

const data = [
  {
    Icon: IconYoga,
    title: "Тренировочный процесс",
    texts: [
      "биомеханика",
      "умный фитнес",
      "гипертрофия",
      "электромиография",
    ],
    to: "#667db6",
    from: "#0082c8",
  },
  {
    Icon: IconCarrot,
    title: "Нутрициология",
    texts: [
      "популярные заблуждения",
      "набор мышечной массы",
      "мифы",
      "исследования",
      "снижение массы тела",
    ],
    from: "green",
    to: "teal",
  },
  {
    Icon: IconHeartbeat,
    title: "ЛФК и реабилитация",
    texts: [
      "грыжи",
      "протрузии",
      "эффективные упражнения",
      "реабилитация",
      "безопасный фитнес",
    ],
    from: "red",
    to: "pink",
  },
  {
    Icon: IconBrain,
    title: "Психология",
    texts: [
      "коммуникации с клиентами",
      "мотивация",
    ],
    to: "#CC95C0",
    from: "#DBD4B4",
  },
  {
    Icon: IconCoins,
    title: "Маркетинг и продвижение",
    texts: [
      "упаковка эксперта",
      "инструменты продвижения 2024",
    ],
    to: "yellow",
    from: "orange",
  },
]

export function Subjects(): React.JSX.Element {
  const { classes, theme } = useStyles()

  return (
    <section className={classes.wrapper}>
      <Container size={1320}>
        <Center className={classes.title}>
          <Title color="#122c0f" order={2} align="center" size={32}>Ключевые темы конференции</Title>
        </Center>

        <SimpleGrid
          cols={3}
          spacing={48}
          breakpoints={[
            { maxWidth: "sm", cols: 2, spacing: 32 },
            { maxWidth: "xs", cols: 1, spacing: 24 },
          ]}
        >
          {data.map(({ title, from, to, texts, Icon }, index) => (
            <Card key={index} px={32} py={32} className={classes.card}>
              <ThemeIcon
                radius="50%"
                size={48}
                variant="gradient"
                gradient={{ from, to }}
                sx={() => ({
                  boxShadow: `${from} 0px 0px 35px`,
                })}
              >
                <Icon size="1.5rem"/>
              </ThemeIcon>

              <Title
                mt={32}
                color="#122c0f"
                order={3}
                size={24}
                sx={() => ({
                  position: "relative",
                  paddingBottom: 32,

                  "&::before": {
                    content: "\"\"",
                    position: "absolute",
                    display: "block",
                    width: 120,
                    height: 3,
                    background: from,
                    boxShadow: `${from} 0px 0px 50px`,
                    bottom: 0,
                    left: 0,
                  },
                })}
              >
                {title}
              </Title>

              <List
                mt={32}
                size="md"
                center
                spacing="sm"
                icon={<IconHash size="1rem" color="gray"/>}
                sx={{ color: `${theme.colors.gray[7]} !important` }}
              >
                {texts.map((el, index) => (
                  <List.Item color="dimmed" key={index}>{el}</List.Item>
                ))}
              </List>
            </Card>
          ))}
        </SimpleGrid>

      </Container>
    </section>
  )
}
