import React, { useEffect, useState } from "react"
import "./index.css"
import { Center, Container, Flex, Title, Text } from "@mantine/core"
import { declOfNum } from "../../shared/utils/declOfNum"

interface TimeLeft {
  days: number
  hours: number
  min: number
  sec: number
}

const format = {
  days: [ "день", "дня", "дней" ],
  hours: [ "час", "часа", "часов" ],
  min: [ "минута", "минуты", "минут" ],
  sec: [ "секунда", "секунды", "секунд" ],
}
const defaultState: TimeLeft = { days: 0, hours: 0, min: 0, sec: 0 }

export function Timer({ date }: { date?: string }): React.JSX.Element {
  const [ timeLeft, setTimeLeft ] = useState<TimeLeft>(defaultState)

  useEffect(() => {
    const intervalId = setInterval(() => {
      const now = new Date()
      const endDate = new Date(date || "")
      const diff = endDate.getTime() - now.getTime()

      if (diff <= 0) {
        clearInterval(intervalId)
        setTimeLeft(defaultState)
      } else {
        const days = Math.floor(diff / (1000 * 60 * 60 * 24))
        const hours = Math.floor((diff % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60))
        const min = Math.floor((diff % (1000 * 60 * 60)) / (1000 * 60))
        const sec = Math.floor((diff % (1000 * 60)) / 1000)

        setTimeLeft({ days, hours, min, sec })
      }
    }, 1000)

    return () => clearInterval(intervalId)
  }, [ date ])

  const addLeadingZeros = (value: number): string => {
    let valueStr = String(value)
    while (valueStr.length < 2) {
      valueStr = "0" + valueStr
    }
    return valueStr
  }

  return (
    <Container pt={60} pb={20} size={1320} color="#122c0f">
      <Center mb={24} sx={(theme) => ({
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        position: "relative",
      })}>
        <Title
          color="#122c0f"
          order={2}
          align="center"
          sx={(theme) => ({
            fontSize: 32,

            [theme.fn.smallerThan("sm")]: {
              fontSize: 24,
            },
          })}
        >
          До повышения цены осталось
        </Title>
      </Center>

      <Center>
        <Flex
          className="timer"
          sx={(theme) => ({
            gap: 24,

            [theme.fn.smallerThan("sm")]: {
              gap: 12,
            },
          })}
        >
          {([ "days", "hours", "min", "sec" ] as const).map((part, index) => (
            <Flex
              align="flex-start"
              justify="space-between"
              sx={(theme) => ({
                gap: 24,
                minWidth: 105,

                [theme.fn.smallerThan("sm")]: {
                  gap: 12,
                  minWidth: 55,
                },
              })}
              key={index}
            >
              <Flex direction="column" align="center" justify="center">
                <Text
                  sx={(theme) => ({
                    fontSize: 44,

                    [theme.fn.smallerThan("sm")]: {
                      fontSize: 22,
                    },
                  })}
                  fw={700}
                >
                  {addLeadingZeros(timeLeft[part])}
                </Text>
                <Text
                  sx={(theme) => ({
                    fontSize: 24,

                    [theme.fn.smallerThan("sm")]: {
                      fontSize: 12,
                    },
                  })}
                  fw={700}
                >
                  {declOfNum(timeLeft[part], format[part])}
                </Text>
              </Flex>
              {index < 3 && <Text
                sx={(theme) => ({
                  fontSize: 40,

                  [theme.fn.smallerThan("sm")]: {
                    fontSize: 20,
                  },
                })}
                fw={700}
              >
                :
              </Text>}
            </Flex>
          ))}
        </Flex>
      </Center>
    </Container>
  )

}

