import React from "react"
import { IconArrowBigUpFilled } from "@tabler/icons-react"
import { useWindowScroll } from "@mantine/hooks"
import { Affix, Transition, rem, ThemeIcon, createStyles } from "@mantine/core"
import { primaryColor, secondaryColor } from "../../../constants"

const useStyles = createStyles((theme) => ({
  button: {
    opacity: 0.5,
    transition: "opacity 0.3s ease-in-out",

    [theme.fn.smallerThan("sm")]: {
      right: rem(8),
    },

    "&:hover": {
      opacity: 1,
    },
  },
}))

export function BackToTop(): React.JSX.Element {
  const [ scroll, scrollTo ] = useWindowScroll()
  const { classes } = useStyles()

  const handleClick = () => scrollTo({ y: 0 })

  return (
    <Affix className={classes.button} position={{ bottom: rem(13), right: rem(99) }}>
      <Transition transition="slide-up" mounted={scroll.y >= 200}>
        {(transitionStyles) => (
          <ThemeIcon
            variant="gradient"
            gradient={{ from: `${primaryColor}.8`, to: `${secondaryColor}.8` }}
            radius="xl"
            size={48}
            style={{ ...transitionStyles, cursor: "pointer" }}
            onClick={handleClick}
          >
            <IconArrowBigUpFilled/>
          </ThemeIcon>
        )}
      </Transition>
    </Affix>
  )
}
