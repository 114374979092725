import { makeAutoObservable } from "mobx"

export interface Speaker {
  time: string
  speakerName: string
  description: string
  value: string
  title: string
  small: string
  cover: string
  texts: Array<string>
  social?: {
    telegram?: string
    instagram?: string
    vk?: string
    youtube?: string
    researchGate?: string
    custom?: string
    tiktok?: string
  }
}

class SpeakerStoreImpl {
  speaker: Speaker | null = null
  isOpened: boolean = false

  constructor() {
    makeAutoObservable(this)
  }

  setSpeaker(speaker: Speaker | null) {
    this.speaker = speaker

    if (speaker) {
      this.isOpened = true
    }
  }

  setIsOpened(state: boolean) {
    this.isOpened = state

    if (!state) {
      setTimeout(() => {
        this.setSpeaker(null)
      }, 300)
    }
  }
}

export const SpeakerStore = new SpeakerStoreImpl()
