import {
  Flex,
  createStyles,
  Stack,
  Text,
  Button,
  Group,
  UnstyledButton,
} from "@mantine/core"
import React from "react"
import { Image } from "../../shared/ui"
import { useMediaQuery } from "@mantine/hooks"
import { type Speaker, SpeakerStore } from "../../app/SpeakerStore"
import { Link } from "react-scroll"
import { primaryColor, secondaryColor } from "../../constants"

const useStyles = createStyles((theme) => ({
  element: {
    alignItems: "flex-start",
    marginBottom: 32,
    paddingBottom: 32,
    borderBottom: "1px solid rgb(195, 209, 224)",

    "&:last-of-type": {
      borderBottom: "none",
    },

    [theme.fn.smallerThan(768)]: {
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
    },
  },

  elementDataContainer: {
    marginTop: 35,
    marginLeft: 80,
    width: 180,
    minWidth: 180,

    [theme.fn.smallerThan(1024)]: {
      marginTop: 20,
      marginLeft: 24,
      width: 155,
      minWidth: 155,
    },

    [theme.fn.smallerThan(768)]: {
      textAlign: "center",
      minWidth: "unset",
      width: "unset",
      margin: 0,
      marginBottom: 24,
    },
  },

  elementTextContainer: {
    marginTop: 30,
    marginLeft: 24,
    marginRight: 40,

    [theme.fn.smallerThan(1024)]: {
      marginRight: 24,
      marginTop: 20,
    },
  },

  elementButton: {
    marginTop: 45,
    marginRight: 80,
    marginLeft: "auto",

    [theme.fn.smallerThan(1024)]: {
      marginRight: 24,
      marginTop: 30,
    },

    [theme.fn.smallerThan(768)]: {
      margin: "24px auto 0 auto",
    },
  },

  elementDataText: {
    fontSize: 24,

    [theme.fn.smallerThan(1024)]: {
      fontSize: 20,
    },

    [theme.fn.smallerThan(768)]: {
      fontSize: 34,
    },
  },

  elementMainText: {
    fontSize: 24,

    [theme.fn.smallerThan(1024)]: {
      fontSize: 20,
    },

    [theme.fn.smallerThan(768)]: {
      textAlign: "center",
    },
  },

  elementSpeakerText: {
    [theme.fn.smallerThan(768)]: {
      textAlign: "center",
      marginTop: 16,
    },
  },
}))

type Props = {
  speaker: Speaker
}

export function Element({ speaker }: Props): React.JSX.Element {
  const { classes } = useStyles()
  const isTablet = useMediaQuery(`(max-width: 1024px)`)
  const isSmallTablet = useMediaQuery(`(max-width: 768px)`)

  const {
    time,
    small,
    speakerName,
    title,
  } = speaker

  const open = () => SpeakerStore.setSpeaker(speaker)
  function getSize() {
    if (isSmallTablet) {
      return 140
    }

    if (isTablet) {
      return 100
    }

    return 140
  }

  return (
    <Flex className={classes.element}>
      <Stack spacing={4} className={classes.elementDataContainer}>
        <Text className={classes.elementDataText} weight={700}>{time}</Text>
      </Stack>

      <UnstyledButton onClick={open}>
        <Image
          ratio="1/1"
          src={small}
          sx={
            {
              borderRadius: "50%",
              width: getSize(),
              height: getSize(),
              minWidth: getSize(),
              minHeight: getSize(),
            }
          }
        />
      </UnstyledButton>
      <Stack spacing={4} className={classes.elementTextContainer}>
        <Text className={classes.elementMainText} weight={700}>{title}</Text>
        <Text className={classes.elementSpeakerText} size={16} c="dimmed">Рассказывает&nbsp;
          <UnstyledButton onClick={open}>
            <Text fw={900} color={`${primaryColor}.8`} component="span">{speakerName}</Text>
          </UnstyledButton>
        </Text>
      </Stack>

      <Group className={classes.elementButton}>
        <Button
          radius="lg"
          size={isTablet ? "sm" : "md"}
          variant="gradient"
          gradient={{ from: `${primaryColor}.7`, to: `${secondaryColor}.7`, deg: 90 }}
          component={Link}
          to={"prices"}
          smooth={true}
          duration={1000}
        >
          Записаться
        </Button>
      </Group>
    </Flex>
  )
}
