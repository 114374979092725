import { Group } from "@mantine/core"
import React, { useEffect } from "react"
import { vkWidgetColor } from "../../constants"

export function VkGroupWidget(): React.JSX.Element {
  useEffect(() => {
    if (VK) {
      VK.Widgets.Group(
        "vk_group",
        { mode: 3, no_cover: 1, height: 400, width: 288, color1: "333333", color2: "fff", color3: vkWidgetColor },
        31941952,
      )
    }
  }, [])

  return (
    <Group position="center">
      <div id="vk_group"/>
    </Group>
  )
}
