import { createStyles, Button, Burger, keyframes, Drawer } from "@mantine/core"
import React, { useState } from "react"
import { Logo } from "../../shared/ui"
import { menuItems } from "../../shared/utils/constants"
import { Link } from "react-scroll"
import { useMediaQuery, useWindowScroll } from "@mantine/hooks"
import { FormModalStore } from "../../app/FormModalStore"
import { primaryColor, secondaryColor } from "../../constants"

export const emphasize = keyframes({
  "from": { visibility: "hidden", width: 0 },
  "to": { visibility: "visible", width: "100%" },
})

const useStyles = createStyles((theme) => ({
  wrapper: {
    height: 90,
    position: "fixed",
    left: 0,
    top: 0,
    right: 0,
    transition: "all 0.3s",
    zIndex: 1000,
    display: "flex",
    alignItems: "center",
    backgroundColor: "transparent",
  },

  wrapperShift: {
    backgroundColor: theme.fn.rgba("#000", 0.8),
    height: 70,
  },

  container: {
    maxWidth: 1320,
    display: "flex",
    alignItems: "center",
    width: "100%",
    paddingRight: theme.spacing.md,
    paddingLeft: theme.spacing.md,
    marginRight: "auto",
    marginLeft: "auto",
  },

  logo: {
    marginRight: "auto",
  },

  navigation: {
    display: "flex",
    gap: theme.spacing.xl,
  },

  drawerNavigation: {
    display: "flex",
    flexDirection: "column",
    gap: theme.spacing.xs,
    paddingTop: theme.spacing.xl,
  },

  button: {
    position: "relative",
    cursor: "pointer",
    color: theme.white,
    fontWeight: 700,
    transition: "0.3s ease-in-out",
    paddingTop: "10px",
    paddingBottom: "10px",
    textDecoration: "none",

    "&:hover": {
      "&::before": {
        content: "\"\"",
        position: "absolute",
        width: 0,
        height: 2,
        bottom: 0,
        left: 0,
        backgroundColor: theme.colors[primaryColor][8],
        visibility: "hidden",
        transition: "all 0.3s ease-in-out 0s",
        animation: `${emphasize} 0.3s ease-in-out forwards`,
      },
    },
  },

  drawerButton: {
    cursor: "pointer",
    color: theme.black,
    fontWeight: 600,
    transition: "0.3s ease-in-out",
    fontSize: theme.fontSizes.lg,
    padding: "10px 16px",
    border: `1px solid ${theme.colors.gray[1]}`,
    background: theme.colors.gray[0],
    borderRadius: theme.radius.lg,
  },

  drawerButtonActive: {
    color: `${theme.colors[primaryColor][8]} !important`,
    borderColor: `${theme.colors[primaryColor][1]}`,
    background: theme.colors[primaryColor][0],
  },

  buttonActive: {
    "&::before": {
      content: "\"\"",
      position: "absolute",
      width: 0,
      height: 2,
      bottom: 0,
      left: 0,
      backgroundColor: theme.colors[primaryColor][8],
      visibility: "hidden",
      transition: "all 0.3s ease-in-out 0s",
      animation: `${emphasize} 0.3s ease-in-out forwards`,
    },
  },

  drawerHeader: {
    background: "transparent",
  },

  drawerContent: {
    position: "relative",
    overflow: "hidden",
    zIndex: 0,

    "&::before": {
      content: "\"\"",
      position: "absolute",
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",
      backgroundImage: "url(/assets/pattern.png)",
      backgroundSize: "cover",
      backgroundPosition: "center",
      opacity: 0.07,
      zIndex: -1,
    },
  },
}))

export function Menu(): React.JSX.Element {
  const { classes, theme, cx } = useStyles()
  const isMd = useMediaQuery(`(max-width: 768px)`)

  const [ scroll ] = useWindowScroll()
  const [ opened, setIsOpened ] = useState(false)

  const condition = scroll.y >= 200

  const toggle = () => setIsOpened(prev => !prev)
  const close = () => setIsOpened(false)

  const label = opened ? "Закрыть меню" : "Открыть меню"

  return (
    <>
      <header className={cx(classes.wrapper, { [classes.wrapperShift]: condition })}>
        <div className={classes.container}>
          <Logo className={classes.logo}/>

          {!isMd && <nav className={classes.navigation}>
            {menuItems.map(({ label, href }, index) => (
              <Link
                hashSpy={true}
                spy={true}
                activeClass={classes.buttonActive}
                className={classes.button}
                key={index}
                to={href}
                smooth={true}
                duration={1000}
                offset={-70}
                href={`#${href}`}
              >
                {label}
              </Link>
            ))}
          </nav>}

          <Button
            variant="gradient"
            gradient={{ from: `${primaryColor}.9`, to: `${secondaryColor}.8`, deg: 90 }}
            ml="xl"
            radius="lg"
            size={isMd ? "xs" : "md"}
            onClick={() => FormModalStore.setData({ target: "Конференция по фитнесу. Блок: Меню. Кнопка: Оставить заявку" })}
          >
            Заказать звонок
          </Button>

          {isMd && <Burger color="white" ml="xl" opened={opened} onClick={toggle} aria-label={label}/>}
        </div>
      </header>

      <Drawer.Root position="right" zIndex={1050} opened={opened} onClose={close}>
        <Drawer.Overlay/>
        <Drawer.Content className={classes.drawerContent}>
          <Drawer.Header className={classes.drawerHeader} pt={32} px={32} pb={24}>
            <Drawer.Title fz={24} fw={900}>Навигация</Drawer.Title>
            <Drawer.CloseButton variant="light" radius="lg" size="xl"/>
          </Drawer.Header>
          <Drawer.Body p={32}>
            <nav className={classes.drawerNavigation}>
              {menuItems.map(({ label, href }, index) => (
                <Link
                  key={index}
                  hashSpy={true}
                  spy={true}
                  activeClass={classes.drawerButtonActive}
                  className={classes.drawerButton}
                  to={href}
                  smooth={true}
                  duration={1000}
                  onClick={close}
                  offset={-70}
                >
                  {label}
                </Link>
              ))}
            </nav>
          </Drawer.Body>
        </Drawer.Content>
      </Drawer.Root>
    </>
  )
}
