import {
  Container,
  Center,
  createStyles,
  Title,
  Tabs,
} from "@mantine/core"
import React from "react"
import { Element } from "./Element"
import { room1, room2 } from "../../shared/utils/constants"
import { primaryColor, secondaryColor } from "../../constants"
import { MultiElement } from "./MultiElement"

const useStyles = createStyles((theme) => ({
  wrapper: {
    marginTop: 20,
    paddingTop: 40,
    marginBottom: 20,
    paddingBottom: 40,
    backgroundColor: theme.colors[primaryColor][0],
    position: "relative",
    overflowX: "clip",
  },

  wave1: {
    top: 0,
    left: 0,
    display: "block",
    position: "absolute",
    height: 40,
    width: "100%",
    background: theme.colors[primaryColor][0],
    transform: "scale(1, 1)",

    "&::before": {
      content: "''",
      display: "block",
      position: "absolute",
      borderRadius: "100%",
      width: "100%",
      height: 300,
      backgroundColor: theme.colors[primaryColor][0],
      right: "-25%",
      top: -20,
    },

    "&::after": {
      content: "''",
      display: "block",
      position: "absolute",
      borderRadius: "100%",
      width: "100%",
      height: 300,
      backgroundColor: theme.white,
      left: "-25%",
      top: -280,
      clipPath: "ellipse(100% -15% at 15% 100%)",
    },
  },

  wave2: {
    bottom: 0,
    left: 0,
    display: "block",
    position: "absolute",
    height: 40,
    width: "100%",
    background: theme.colors[primaryColor][0],
    transform: "scale(1, 1)",

    "&::before": {
      content: "''",
      display: "block",
      position: "absolute",
      borderRadius: "100%",
      width: "100%",
      height: 300,
      backgroundColor: "white",
      right: "-25%",
      top: 20,
    },

    "&::after": {
      content: "''",
      display: "block",
      position: "absolute",
      borderRadius: "100%",
      width: "100%",
      height: 300,
      backgroundColor: theme.colors[primaryColor][0],
      left: "-25%",
      top: -240,
      clipPath: "ellipse(100% 15% at -15% 100%)",
    },
  },

  title: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    marginBottom: 40,
    position: "relative",
    paddingBottom: 20,

    "&::before": {
      content: "\"\"",
      position: "absolute",
      display: "block",
      width: 90,
      height: 5,
      background: theme.colors[primaryColor][9],
      bottom: 0,
      left: "calc(50% - 45px)",
    },
  },

  tab: {
    transition: "all 0.2s ease-in-out",
    color: theme.black,
    fontWeight: 700,
    fontSize: 18,
    border: `2px dashed ${theme.colors[primaryColor][7]}`,

    "&:hover": {
      backgroundColor: theme.colors[primaryColor][2],
    },

    "&[data-active]": {
      borderColor: theme.colors[primaryColor][7],
      background: theme.fn.linearGradient(45, theme.colors[primaryColor][7], theme.colors[secondaryColor][7]),
    },
  },
}))

export function Schedule(): React.JSX.Element {
  const { classes } = useStyles()

  return (
    <section id="schedule" className={classes.wrapper}>
      <div className={classes.wave1}/>
      <div className={classes.wave2}/>

      <Container sx={{ zIndex: 1, position: "relative" }} size={1320}>
        <Center className={classes.title}>
          <Title color="#122c0f" order={2} align="center" size={32}>Расписание выступлений</Title>
        </Center>

        <Tabs color={primaryColor} variant="pills" radius="lg" defaultValue="room1">
          <Center mb={24}>
            <Tabs.List sx={{ gap: 16 }}>
              <Tabs.Tab className={classes.tab} value="room1">1-ый зал</Tabs.Tab>
              <Tabs.Tab className={classes.tab} value="room2">2-й зал</Tabs.Tab>
            </Tabs.List>
          </Center>

          <Tabs.Panel value="room1" pt="xs">
            {room1.map((el) => {
              if (el.variant === "multi") {
                return (
                  <div></div>
                )
              }
              return <Element key={el.title + el.value} speaker={el}/>
            })}
          </Tabs.Panel>

          <Tabs.Panel value="room2" pt="xs">
            {room2.map((el, index) => {
              if (el.variant === "multi") {
                return <MultiElement key={el.title + index} element={el}/>
              }
              return <Element key={el.title + el.value} speaker={el}/>
            })}
          </Tabs.Panel>

          <Tabs.Panel value="room2" pt="xs">
          </Tabs.Panel>
        </Tabs>

      </Container>
    </section>
  )
}
