import React from "react"
import {
  Center,
  Container,
  createStyles,
  Title,
  SimpleGrid,
  Flex,
  Text,
  Box,
  UnstyledButton,
} from "@mantine/core"
import { Image } from "../../shared/ui"
import { type Speaker, SpeakerStore } from "../../app/SpeakerStore"
import { room1, room2, RoomElement } from "../../shared/utils/constants"
import { primaryColor } from "../../constants"

const useStyles = createStyles((theme) => ({
  wrapper: {
    paddingTop: 60,
    paddingBottom: 80,
    zIndex: 1,
    position: "relative",
  },

  title: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    marginBottom: 40,
    position: "relative",
    paddingBottom: 20,

    "&::before": {
      content: "\"\"",
      position: "absolute",
      display: "block",
      width: 90,
      height: 5,
      background: theme.colors[primaryColor][8],
      bottom: 0,
      left: "calc(50% - 45px)",
    },
  },

  grid: {
    gap: 24,

    [theme.fn.smallerThan("xs")]: {
      gap: 12,
    },
  },

  name: {
    color: theme.white,
    fontWeight: 700,
    fontSize: 16,

    [theme.fn.smallerThan("md")]: {
      fontSize: "1.25rem",
    },
  },

  cardBox: {
    position: "absolute",
    left: 0,
    right: 0,
    bottom: 0,
    padding: "12px 16px",
    zIndex: 10,
    backgroundColor: "rgba(0,0,0,0.7)",
  },

  cardWrapper: {
    position: "relative",
    overflow: "hidden",
    borderRadius: theme.radius.lg,
  },
}))

type Props = {
  speaker: Speaker
}

export function Card(props: Props): React.JSX.Element {
  const { classes } = useStyles()
  const { speaker } = props

  const open = () => SpeakerStore.setSpeaker(speaker)

  return (
    <UnstyledButton onClick={open}>
      <Flex direction="column" align="center" className={classes.cardWrapper}>
        <Image
          ratio="1/1"
          alt={speaker.speakerName}
          src={speaker.small}
        />
        <Box className={classes.cardBox}>
          <Center>
            <Text align="center" className={classes.name}>
              {speaker.speakerName}
            </Text>
          </Center>
        </Box>
      </Flex>
    </UnstyledButton>
  )
}

export function Speakers(): React.JSX.Element {
  const { classes } = useStyles()

  return (
    <section id="speakers" className={classes.wrapper}>
      <Container size={1024}>
        <Center className={classes.title}>
          <Title color="#122c0f" order={2} align="center" size={32}>Спикеры</Title>
        </Center>

        <SimpleGrid
          cols={3}
          breakpoints={[
            { maxWidth: "md", cols: 2 },
            { maxWidth: "xs", cols: 1 },
          ]}
          className={classes.grid}
        >
          {getSpeakers([ ...room1, ...room2 ]).map((el, index) => (
            <Card key={index} speaker={el}/>
          ))}
        </SimpleGrid>
      </Container>
    </section>
  )
}

function getSpeakers(elements: Array<RoomElement>): Array<Speaker> {
  const speakers: Array<Speaker> = []

  for (const element of elements) {
    if (element.variant === "multi") {
      speakers.push(...element.team)
      continue
    }
    speakers.push(element)
  }

  return speakers
}
